import { KnowledgeBaseArticleLinkPipe } from './knowledge-base-article-link.pipe';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { KnowledgeBaseArticleConfig } from '../+authenticated/shared/knowledge-base-article/knowledge-base-article.config';
import { KnowledgeBaseArticleComponent } from '../+authenticated/shared/knowledge-base-article/knowledge-base-article';
import { IconComponent } from '../+authenticated/shared/icon.component';

const declarations = [KnowledgeBaseArticleLinkPipe, KnowledgeBaseArticleComponent];
@NgModule({
  imports: [IconComponent],
  declarations: declarations,
  exports: declarations,
  providers: [KnowledgeBaseArticleConfig, KnowledgeBaseArticleLinkPipe],
})
export class KnowledgeBaseArticleLinkModule {
  public static forRoot(): ModuleWithProviders<KnowledgeBaseArticleLinkModule> {
    return {
      ngModule: KnowledgeBaseArticleLinkModule,
    };
  }
}
