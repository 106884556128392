/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable()
export class KnowledgeBaseArticleConfig {
  public baseUrl = environment.knowledgeBaseUrl;
  public availableLanguagesByLocale = {
    'nl-NL': 'nl',
    'en-GB': 'en',
    'de-DE': 'de',
  };

  public articles = {
    'scheduling-shifts': {
      en: 'scheduling-shifts',
      nl: 'dienst-inroosteren',
      de: 'schicht-einplanen',
    },
    'adding-employees': {
      en: 'adding-employees',
      nl: 'medewerker-aanmaken',
      de: 'mitarbeiter-erstellen',
    },
    'user-permissions': {
      en: 'user-permissions',
      nl: 'toegangsrechten-van-medewerkers',
      de: 'berechtigungen-für-mitarbeiter',
    },
    'overtime-surcharge': {
      en: 'overtime-surcharge',
      nl: 'overwerkbeleid',
      de: 'überstundenzuschlag',
    },
    'sending-schedules': {
      en: 'sending-schedules',
      nl: 'werkrooster-versturen',
      de: 'dienstplan-senden',
    },
    'importing-employees': {
      en: 'importing-employees',
      nl: 'medewerkers-importeren',
      de: 'mitarbeiter-importieren',
    },
    'calendar-synchronisation': {
      en: 'calendar-synchronisation',
      nl: 'agenda-synchronisatie',
      de: 'kalender-synchronisation',
    },
    'publishing-the-schedule': {
      en: 'publishing-the-schedule',
      nl: 'werkrooster-publiceren',
      de: 'dientsplan-veröffentlichen',
    },
    'manage-account-information': {
      en: 'manage-account-information',
      nl: 'accountgegevens-beheren',
      de: 'kontoinformationen-verwalten',
    },
    'adding-news-items': {
      en: 'adding-news-items',
      nl: 'nieuwsberichten-toevoegen',
      de: 'nachricht-hinzufügen',
    },
    'adding-absence': {
      en: 'adding-absence',
      nl: 'afwezigheid-registreren',
      de: 'abwesenheit-hinzufügen',
    },
    'adding-a-forecast': {
      en: 'adding-a-forecast',
      nl: 'verwachte-omzet-instellen',
      de: 'prognose-einstellen',
    },
    'managing-skills': {
      en: 'managing-skills',
      nl: 'vaardigheden-beheren',
      de: 'f%C3%A4higkeiten-verwalten',
    },
    'record-working-hours-manually': {
      en: 'record-working-hours-manually',
      nl: 'werktijden-handmatig-registreren',
      de: 'arbeitsstunden-manuell-hinzufügen',
    },
    'managing-teams': {
      en: 'managing-teams',
      nl: 'teams-beheren',
      de: 'teams-verwalten',
    },
    'printing-the-work-schedule': {
      en: 'printing-the-work-schedule',
      nl: 'werkrooster-printen',
      de: 'dienstplan-drucken',
    },
    'plus-minus-hours': {
      en: 'plus-minus-hours',
      nl: 'plus-minuren',
      de: 'plus-minus-stunden',
    },
    'my-plus-minus': {
      en: 'my-plus-minus',
      nl: 'mijn-plus-min',
      de: 'meine-plus-minus-stunden',
    },
    'rate-cards': {
      en: 'rate-cards',
      nl: 'toeslagkaarten-beheren',
      de: 'zuschlagskarten-verwalten',
    },
    'managing-shift-types': {
      en: 'managing-shift-types',
      nl: 'diensttypes-beheren',
      de: 'schichttypen-verwalten',
    },
    'managing-shift-types-settings': {
      en: 'managing-shifts-in-the-settings',
      nl: 'diensttypes-beheren-in-de-instellingen',
      de: 'verwaltung-der-schichttypen-in-den-einstellungen',
    },
    'public-holidays': {
      en: 'public-holidays',
      nl: 'feestdagen-instellen',
      de: 'feiertage-einstellen',
    },
    absence: {
      en: 'settings#leave',
      nl: 'einstellungen#abwesenheit',
      de: 'instellingen#afwezigheid',
    },
    'managing-locations-departments': {
      en: 'managing-locations-departments',
      nl: 'locaties-afdelingen-beheren',
      de: 'verwalte-standorte-und-abteilungen',
    },
    'employee-contracts': {
      en: 'employee-contracts',
      nl: 'medewerkerscontracten',
      de: 'arbeitsverträge',
    },
    'managing-absence-types': {
      en: 'managing-absence-types',
      nl: 'afwezigheidstypes-beheren',
      de: 'abwesenheitsarten-verwalten',
    },
    'setting-up-required-shifts': {
      en: 'setting-up-required-shifts',
      nl: 'bezetting-instellen',
      de: 'erforderliche-dienste-einstellen',
    },
    'open-shifts': {
      en: 'open-shifts',
      nl: 'open-diensten',
      de: 'offene-schichten',
    },
    'my-schedule': {
      en: 'my-schedule',
      nl: 'mijn-rooster',
      de: 'mein-dienstplan',
    },
    'managing-notifications': {
      en: 'managing-notifications',
      nl: 'berichtgeving-instellen',
      de: 'benachrichtigung-einstellen',
    },
    permissions: {
      en: 'permissions',
      nl: 'toegangsrechten',
      de: 'zugangsrechte',
    },
    'contract-types': {
      en: 'contract-types',
      nl: 'contracttypes',
      de: 'vertragsarten',
    },
    'editing-deleting-shifts': {
      en: 'editing-deleting-shifts',
      nl: 'dienst-wijzigen-verwijderen',
      de: 'schicht-ändern-und-löschen',
    },
    'adding-events': {
      en: 'adding-events',
      nl: 'agendapunt-toevoegen',
      de: 'ereignisse-hinzufügen',
    },
    'employee-files': {
      en: 'employee-files',
      nl: 'medewerkersbestanden',
      de: 'mitarbeiterdateien',
    },
    'my-absence': {
      en: 'my-absence',
      nl: 'mijn-afwezigheid',
      de: 'meine-abwesenheit',
    },
    'setting-the-budget': {
      en: 'setting-the-budget',
      nl: 'budget-instellen',
      de: 'das-budget-festlegen',
    },
    'employee-notes': {
      en: 'employee-notes',
      nl: 'medewerkersnotities',
      de: 'mitarbeiter-notizen',
    },
    'custom-fields-employees': {
      en: 'custom-fields-employees',
      nl: 'vrije-velden-medewerkers',
      de: 'zusätzliche-felder-mitarbeiter',
    },
    'custom-fields-schedule-timesheet': {
      en: 'custom-fields-schedule-timesheet',
      nl: 'vrije-velden-rooster-urenregistratie',
      de: 'zus%C3%A4tzliche-felder-dienstplan-arbeitszeitprotokoll',
    },
    'absence-restrictions': {
      en: 'absence-restrictions',
      nl: 'afwezigheidsbeperkingen',
      de: 'abwesenheitslimitierungen',
    },
    'managing-time-off-balances': {
      en: 'managing-time-off-balances',
      nl: 'verlofsaldos-beheren',
      de: 'abwesenheitssalden-verwalten',
    },
    'bulk-action-balance-correction': {
      en: 'bulk-action-balance-correction',
      nl: 'bulkactie-corrigeer-saldo',
      de: 'bulk-aktion-bilanz-korrektur',
    },
    'diary-1': {
      en: 'diary-1',
      nl: 'dagboek-1',
      de: 'tagebuch-1',
    },
    'bulk-action-change-contract': {
      en: 'bulk-action-change-contract',
      nl: 'bulkactie-wijzig-contract',
      de: 'bulk-aktion-vertrag-%C3%A4ndern',
    },
    'bulk-action-change-salary': {
      en: 'bulk-action-change-salary',
      nl: 'bulkactie-wijzig-salaris',
      de: 'bulk-aktion-lohn-%C3%A4ndern',
    },
    'bulk-action-send-new-login-details': {
      en: 'bulk-action-send-new-login-details',
      nl: 'bulkactie-verstuur-nieuwe-inloggegevens',
      de: 'bulk-aktion-neue-zugangsdaten-senden',
    },
    'bulk-action-add-absence': {
      en: 'bulk-action-add-absence',
      nl: 'bulkactie-afwezigheid-toevoegen',
      de: 'bulk-aktion-abwesenheit-hinzufügen',
    },
    'general-schedule-settings': {
      en: 'general-schedule-settings',
      nl: 'algemene-rooster-instellingen',
      de: 'allgemeine-dienstplan-einstellungen',
    },
    'managing-surcharges': {
      en: 'managing-surcharges',
      nl: 'toeslagen-beheren',
      de: 'zuschläge-verwalten',
    },
    'time-off-balance-correction': {
      en: 'time-off-balance-correction',
      nl: 'verlofsaldo-correctie',
      de: 'abwesenheitssaldo-korrektur',
    },
    'plus/minus-hour-correction': {
      en: 'plus/minus-hour-correction',
      nl: 'plus/minuren-correctie',
      de: 'plus-minus-stunden-korrektur',
    },
    'kiosk-settings': {
      en: 'kiosk-settings',
      nl: 'kiosk-instellingen',
      de: 'kiosk-einstellungen',
    },
    'timesheet-settings': {
      en: 'timesheet-settings',
      nl: 'urenregistratie-instellingen',
      de: 'zeiterfassung-einstellungen',
    },
    'punch-clock-management': {
      en: 'punch-clock-management',
      nl: 'in-en-uitklokbeheer',
      de: 'verwaltung-von-ein-und-ausstempeln',
    },
    'department-settings-of-the-schedule': {
      en: 'department-settings-of-the-schedule',
      nl: 'afdelingsinstellingen-rooster',
      de: 'abteilungseinstellungen-dienstplan',
    },
    'break-management': {
      en: 'break-management',
      nl: 'pauze-beheer',
      de: 'pausen-management',
    },
    'availability-rules': {
      en: 'availability-rules',
      nl: 'beschikbaarheidsregels',
      de: 'verf%C3%BCgbarkeitsregeln',
    },
    notifications: {
      en: 'notifications',
      nl: 'meldingen',
      de: 'benachrichtigungen',
    },
    'quick-start-guide-new-account': {
      en: 'quick-start-guide-new-account',
      nl: 'snelstart-nieuwe-accounts',
      de: 'schnellstart-anleitung-neues-konto',
    },
    'absence-policies': {
      en: 'absence-policies',
      nl: 'afwezigheidsbeleid',
      de: 'abwesenheitsrichtlinien',
    },
    'department-variations': {
      en: 'department-variations',
      nl: 'afdelingsvariaties',
      de: 'abteilungskonfigurationen',
    },
    'my-hours': {
      en: 'my-hours',
      nl: 'mijn-uren',
      de: 'meine-stunden',
    },
    'my-overtime': {
      en: 'my-overtime',
      nl: 'mijn-plus-min',
      de: 'meine-plus-minus-stunden',
    },
    timesheet: {
      en: 'timesheet',
      nl: 'urenregistratie',
      de: 'arbeitszeitprotokoll',
    },
    'reports-1': {
      en: 'reports-1',
      nl: 'rapportages-1',
      de: 'statistiken-1',
    },
    'absence-in-days': {
      en: 'migrating-to-absence-in-days',
      nl: 'migreren-naar-afwezigheid-in-dagen',
      de: 'migration-zu-abwesenheit-in-tagen',
    },
    'copy-schedule': {
      en: 'copy-schedule',
      nl: 'rooster-kopieren',
      de: 'dienstplan-kopieren',
    },
    'clock-boundaries': {
      en: 'clock-boundaries',
      nl: 'klok-beperkingen',
      de: 'stempelgrenzen',
    },
    'opening-and-closing-days': {
      en: 'opening-and-closing-days',
      nl: 'dagen-openen-en-sluiten',
      de: 'tage-öffnen-und-schließen',
    },
    'clocking-working-hours-with-desktop-app': {
      en: 'clocking-working-hours-with-desktop-app',
      nl: 'werktijden-klokken-via-desktop-en-app',
      de: 'zeiterfassung-über-desktop-und-app',
    },
    'break-rules': {
      en: 'break-management#Break-rules',
      nl: 'pausen-managementr',
      de: 'pauze-beheer',
    },
    'printing-timesheet': {
      en: 'printing-timesheet',
      nl: 'urenregistratie-afdrukken',
      de: 'arbeitszeitnachweis-drucken',
    },
    'overview-of-absences': {
      en: 'overview-of-absences',
      nl: 'afwezigheidsoverzicht',
      de: 'abwesenheiten-im-ueberblick',
    },
    'how-can-i-add-public-holidays': {
      en: 'how-can-i-add-public-holidays',
      nl: 'hoe-voeg-ik-nationale-feestdagen-toe',
      de: 'wie-kann-ich-feiertage-hinzufügen',
    },
  };
}
