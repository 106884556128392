import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { KnowledgeBaseArticleConfig } from '../+authenticated/shared/knowledge-base-article/knowledge-base-article.config';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { getAuthenticatedUserLocale } from '../reducers/orm/employee/employee.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'knowledgeBaseArticleLink',
  pure: true,
})
export class KnowledgeBaseArticleLinkPipe implements PipeTransform, OnDestroy {
  private onLocaleChange: Subscription;
  private value: string;

  public constructor(
    private config: KnowledgeBaseArticleConfig,
    private store: Store<AppState>,
    private _ref: ChangeDetectorRef
  ) {}

  public transform(article: string): string {
    // if there is a subscription to onLocaleChange, clean it
    this._dispose();

    this.onLocaleChange = this.store
      .select(getAuthenticatedUserLocale)
      .pipe(distinctUntilChanged())
      .pipe(first())
      .subscribe((locale) => {
        this.value = this.articleUrlFromLocale(locale, article);
        this._ref.markForCheck();
      });

    return this.value;
  }

  public articleUrlFromLocale(locale: string, article: string) {
    let articleLanguage = 'en';
    if (this.config.availableLanguagesByLocale[locale]) {
      articleLanguage = this.config.availableLanguagesByLocale[locale];
    }

    let articleLanguageSubDirectory = '';
    if (articleLanguage !== 'en') {
      articleLanguageSubDirectory = articleLanguage + '/';
    }

    if (!this.config.articles[article]) {
      return `${this.config.baseUrl}/${articleLanguageSubDirectory}`;
    }

    const articleSubDirectory = this.config.articles[article][articleLanguage];

    return `${this.config.baseUrl}/${articleLanguageSubDirectory}${articleSubDirectory}`;
  }

  private _dispose(): void {
    if (typeof this.onLocaleChange !== 'undefined') {
      this.onLocaleChange.unsubscribe();
      this.onLocaleChange = undefined;
    }
  }

  public ngOnDestroy(): void {
    this._dispose();
  }
}
