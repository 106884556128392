import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Subscription } from 'rxjs';
import { getAuthenticatedUserLocale } from '../../../reducers/orm/employee/employee.service';
import { FeatureService } from '../../../startup/feature.service';
import { KnowledgeBaseArticleConfig } from './knowledge-base-article.config';
import { KnowledgeBaseArticleLinkPipe } from '../../../pipes/knowledge-base-article-link.pipe';

@Component({
  selector: 'knowledge-base-article',
  template: `
    <a target="_blank" class="article-icon-button flex" [href]="this.link">
      <icon class="si" svgIcon="help"></icon>
    </a>
  `,
})
export class KnowledgeBaseArticleComponent implements OnInit, OnDestroy {
  /** all swatch colors */
  @Input()
  public article: string;

  public link: string;
  private dataSubs = new Subscription();

  public constructor(
    private config: KnowledgeBaseArticleConfig,
    private store: Store<AppState>,
    private featureService: FeatureService,
    private knowledgeBaseArticleLinkPipe: KnowledgeBaseArticleLinkPipe
  ) {}

  public ngOnInit(): void {
    this.dataSubs.add(
      this.store
        .select(getAuthenticatedUserLocale)
        .pipe(first())
        .subscribe((locale) => {
          this.link = this.knowledgeBaseArticleLinkPipe.articleUrlFromLocale(locale, this.article);
        })
    );
  }

  ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }
}
